
import { mapActions, mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { ISteamAccount } from '../entities/player';
import { useMainStore } from '../store/mainStore';
import { useOrgStore } from '../store/orgStore';
import { useTokenStore } from '../store/tokenStore';

import Account from '../components/Account.vue';
import ExternalAnchor from '../components/ExternalAnchor.vue';
import Modal from '../components/Modal.vue';
import SignInButton from '../components/SignInButton.vue';
import SignInCard from '../components/SignInCard.vue';
import Tooltip from '../components/Tooltip.vue';

export default defineComponent({
  components: {
    Account,
    ExternalAnchor,
    Modal,
    SignInButton,
    SignInCard,
    Tooltip,
  },
  computed: {
    getDiscordGuildInviteUri(): string | undefined {
      if (!this.settings) {
        return undefined;
      }

      if (this.settings.discordGuildInviteUri.startsWith('http')) {
        return this.settings.discordGuildInviteUri;
      }

      return `https://${this.settings.discordGuildInviteUri}`;
    },
    ...mapWritableState(useMainStore, ['loading']),
    ...mapState(useOrgStore, ['settings']),
    ...mapState(useTokenStore, ['expiration', 'isAuthenticated', 'player']),
  },
  data() {
    return {
      showDiscordModal: false,
      showSteamModal: false,
      steamID: undefined as string | undefined,
      loadingSteamIDs: [] as string[],
    };
  },
  methods: {
    ...mapActions(useOrgStore, ['getOrg']),
    ...mapActions(useTokenStore, [
      'getToken',
      'redirectToDiscord',
      'redirectToSteam',
      'refreshSteamAccount',
    ]),
    openPopup() {
      const popup = window.open(
        `https://steamcommunity.com/gid/${this.settings?.steamGroupId}`,
        'Steam Group',
        'width=1200,height=600',
      );

      if (!popup) {
        return;
      }

      popup.focus();

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          if (this.steamID) {
            this.showSteamModal = false;
            this.refreshSteamGroups(this.steamID);
          }
        }
      }, 500);
    },
    async refreshSteamGroups(steamID: string) {
      if (this.loadingSteamIDs.includes(steamID)) {
        return;
      }

      this.loadingSteamIDs.push(steamID);
      await this.refreshSteamAccount(steamID);
      this.loadingSteamIDs = this.loadingSteamIDs.filter((id: string) => id !== steamID);
    },
  },
  async mounted() {
    await this.getOrg();

    if (!this.isAuthenticated) {
      await this.getToken();
    }

    this.loading = false;

    const { steamID } = this.$route.query;

    if (!steamID) {
      return;
    }

    this.$router.replace({ name: 'Home' });

    if (!this.settings?.steamGroupId) {
      return;
    }

    this.steamID = steamID.toString();

    const account = this.player.steamAccounts.find(
      (a: ISteamAccount) => a.steamId === this.steamID,
    );

    if (account.isSteamGroupMember) {
      return;
    }

    this.showSteamModal = true;
  },
});
