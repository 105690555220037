import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sign-in-button--success",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_external_anchor = _resolveComponent("external-anchor")!

  return (_ctx.success)
    ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
        _createVNode(_component_fa_icon, { icon: ['far', 'check'] }),
        _createTextVNode(" " + _toDisplayString(_ctx.providerNameMap[_ctx.provider]) + " Linked ", 1)
      ]))
    : (_openBlock(), _createBlock(_component_external_anchor, {
        key: 1,
        class: _normalizeClass(_ctx.providerClassMap[_ctx.provider]),
        "show-icon": false
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class"]))
}