<template>
  <section class="sign-in-card">
    <div class="sign-in-card__info">
      <h2 class="sign-in-card__title"><slot name="title" /></h2>
      <slot name="text" />
    </div>

    <slot name="accounts" />

    <slot name="button" />
  </section>
</template>

<style lang="scss">
@import '@/assets/stylesheets/breakpoints.scss';
@import '@/assets/stylesheets/colors.scss';

.sign-in-card {
  background-color: $gray-1;
  border-radius: 2px;
  padding: 2em;
  width: 100%;

  &__title {
    margin: 0;
  }

  &__logo {
    color: $medium-em;
    margin-right: 0.25em;
  }

  &__text {
    margin-top: 19.92px;
  }

  &__accounts {
    display: block;
  }

  &__accounts-list {
    margin: 1.5em 0 0;
    padding: 0;
  }
}

@media screen and (min-width: $large) {
  .sign-in-card {
    align-items: center;
    column-gap: 4em;
    display: flex;
    flex-wrap: wrap;

    &__info {
      flex: 3;
      order: 1;
    }

    &__accounts {
      margin-top: 0;
      order: 3;
      width: 100%;
    }

    &__button {
      flex: 1 12em;
      margin-top: 0;
      max-width: 24em;
      order: 2;
    }
  }
}
</style>
