import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = { class: "tooltip__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _renderSlot(_ctx.$slots, "content"),
    _createElementVNode("span", {
      class: _normalizeClass({ 'tooltip__text-container': true, 'tooltip__text-container--large': _ctx.large })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "text")
      ])
    ], 2)
  ]))
}