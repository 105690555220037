
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import { useTokenStore } from '@/store/tokenStore';
import Modal from './Modal.vue';
import Tooltip from './Tooltip.vue';

export default defineComponent({
  components: {
    Modal,
    Tooltip,
  },
  data() {
    return {
      loading: false,
      showUnlinkModal: false,
      showUpdatePrimaryModal: false,
      newPrimaryAccountId: undefined,
    };
  },
  methods: {
    ...mapActions(useTokenStore, ['unlinkAccount', 'updatePrimaryAccount']),
    async unlink() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      await this.unlinkAccount(this.provider, this.id);
      this.loading = false;
    },
    async setPrimaryAccount() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      await this.updatePrimaryAccount(this.provider, this.id);
      this.showUpdatePrimaryModal = false;
      this.loading = false;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isPrimary: {
      type: Boolean,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
    showRewards: {
      type: Boolean,
      required: true,
    },
  },
});
