import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal__container",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_fa_icon, {
        class: "modal__close",
        icon: ['far', 'times'],
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }),
      _createElementVNode("h2", null, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createElementVNode("p", null, [
        _renderSlot(_ctx.$slots, "message")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "buttons")
      ])
    ])
  ]))
}